import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/Inter/Inter-Regular.ttf";
import { AppProvider } from "./expi-app/app/AppContext";
import { FashionAppProvider } from "./fashion-expi-app/app/AppContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { LoadScript } from "@react-google-maps/api";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartProvider } from "react-use-cart";
import mixpanel from "mixpanel-browser";
import { FilterProvider } from "./fashion-expi-app/app/filters/FilterContext";

const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&loading=async&callback=Function.prototype`;
const googleLoginKey = process.env.REACT_APP_GOOGLE_LOGIN_KEY || "";
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || "";

mixpanel.init(mixpanelToken);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppProvider>
    <CartProvider>
      <FilterProvider>
        <FashionAppProvider>
          <I18nextProvider i18n={i18n}>
            <LoadScript googleMapsApiKey={apiKey}>
              <GoogleOAuthProvider clientId={googleLoginKey}>
                <App />
              </GoogleOAuthProvider>
            </LoadScript>
          </I18nextProvider>
        </FashionAppProvider>
      </FilterProvider>
    </CartProvider>
  </AppProvider>
);
